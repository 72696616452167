@import 'https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css';
@import './css/dashboard.min.css';
@import './css/grapes.css';
@import './css/App.css';

html,
body,
#root {
  height: 100vh;
}

html {
  font-size: 14px;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #fff;
}

.btn.focus,
.btn:focus,
button:focus {
  box-shadow: none !important;
  outline: 0 !important;
}

.drawer {
  width: 260px;
}

.drawer-hidden {
  width: 0;
}

.content {
  width: calc(100% - 260px);
  float: right;
}

.content.content-lg {
  width: 100%;
}

.card-header h6 {
  line-height: 1.98;
}

.dropdown-toggle::after {
  display: none !important;
}

.Toastify__toast--error {
  background: #dc3545 !important;
}

.object-fit-cover {
  object-fit: cover;
}

.checkout-payment-items > td,
.checkout-payment-tax > th,
.checkout-payment-tax > td,
.checkout-payment-shipping > th,
.checkout-payment-shipping > td {
  border-top: none !important;
}
